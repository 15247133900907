import React, { Component } from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import { ParallaxProvider } from "react-scroll-parallax"
import { Parallax } from "react-scroll-parallax"

import { TweenMax } from "gsap"

import s from "./aboutStyles.module.scss"

//import ShowWindowDimensions from "../components/ShowWindowDimensions"

class AboutMePage extends Component {
   constructor(props) {
      super(props)
      this.hrEl = null
      this.hrTweenIn = null
      this.hrTweenOut = null

      this.state = { narrowScreen: false }
   }

   componentDidMount() {
      this.hrTweenIn = TweenMax.from(this.hrEl, 0.7, {
         width: 0,
         opacity: 0,
         paused: false,
      })
      this.hrTweenOut = TweenMax.to(this.hrEl, 0.7, {
         width: 0,
         opacity: 0,
         paused: true,
      })

      this.updateDimensions()
      window.addEventListener("resize", this.updateDimensions)
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.transitionStatus === "entering") {
         this.hrTweenIn.play()
      } else if (this.props.transitionStatus === "exiting") {
         this.hrTweenOut.play()
      }
   }

   componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions)
   }

   updateDimensions = () => {
      window.innerWidth < 540
         ? this.setState({ narrowScreen: true })
         : this.setState({ narrowScreen: false })
   }

   render() {
      const pageImages = this.props.data.allFile.nodes.map(
         image => image.childImageSharp.fluid
      )

      const animationStatus = {
         transitionStatus: this.props.transitionStatus,
         entry: this.props.entry,
         exit: this.props.exit,
      }

      const footerContent = {
         C2As: [
            {
               text: "There's info about some recent production work here...",
               link: "featured-work",
            },
            {
               text: "Or browse my full discography here...",
               link: "full-discography",
            },
            {
               text: "I'm also involved in numerous other creative projects...",
               link: "projects",
            },
         ],
         styles: {
            backgroundColor: "transparent",
         },
      }

      return (
         <ParallaxProvider>
            <div className={`mainContainer ${s.aboutMePage}`}>
               <Layout
                  title="About Me"
                  superTitle="Mr BJ Jackson"
                  pageStyles={s}
                  animationStatus={animationStatus}
                  footerContent={footerContent}
               >
                  <h4 className={s.introduction}>
                     Whether it’s producing, engineering, mixing, sound designing or songwriting. I’m able to accomodate a broad range of projects and
                     budgets. So whatever your needs, if you’re interested in
                     working with me please get in touch and we can chat about
                     how best to do it.
                  </h4>
                  <h4 className={s.introduction}>
                     Or for more info about my{" "}
                     <a href="about-me#history" title="To History section">
                        background
                     </a>{" "}
                     or{" "}
                     <a href="about-me#whereIWork" title="To Where section">
                        where
                     </a>{" "}
                     I typically work, carry on reading. ↓
                  </h4>
                  <hr ref={hr => (this.hrEl = hr)} />

                  <div className="articleSection row1" id="history">
                     <div className="left">
                        <h4>History</h4>
                        <p>
                           After working as a studio assistant at some of the
                           UK’s most delicious studios (i.e. <a href="https://en.wikipedia.org/wiki/Olympic_Studios" title="Olympic Studios on Wikipedia" target="_blank" rel="noopener noreferrer">Olympic</a> & <a href="https://en.wikipedia.org/wiki/Sarm_West_Studios" title="Sarm on Wikipedia" target="_blank" rel="noopener noreferrer">Sarm</a>) I
                           got first my first production gig when my band <a href="https://www.facebook.com/tokillaking/" title="To Kill A King on Facebook" target="_blank" rel="noopener noreferrer">To
                           Kill A King</a> (I’m a keyboard player) signed to Virgin
                           Records and I produced some songs on our debut EP <a href="https://open.spotify.com/album/7IkUcjB3gdXr2Qa4WK4fQe?si=rajS7tWdQESPTZuKvcCEwg" title="My Crooked Saint on Spotify" target="_blank" rel="noopener noreferrer">“My
                           Crooked Saint”</a>
                        </p>
                     </div>
                     <div className="right">
                        <Parallax
                           y={["-50px", "50px"]}
                           disabled={this.state.narrowScreen}
                        >
                           <Image
                              fluid={pageImages.find(
                                 image => image.originalName === "OnStage.jpg"
                              )}
                           />
                        </Parallax>
                     </div>
                  </div>

                  <div className="articleSection row2">
                     <div className="left">
                        <Parallax
                           y={["-50px", "50px"]}
                           disabled={this.state.narrowScreen}
                        >
                           <Image
                              fluid={pageImages.find(
                                 image =>
                                    image.originalName ===
                                    "fisheye-pressplay.jpg"
                              )}
                           />
                        </Parallax>
                     </div>
                     <div className="right">
                        <p>
                           In 2014 I started working with other people.
                           Initially it was other indie bands I met while
                           touring with To Kill A King but these days I work on
                           all kinds of projects be it pop music, guitar music,
                           electronica, I just love good music in all its shapes
                           and sizes.
                        </p>
                        <p>
                           To date I’ve released 100+ songs and numerous albums
                           and eps.
                        </p>
                     </div>
                  </div>

                  <div className="articleSection row3" id="whereIWork">
                     <div className="left">
                        <h4>Where do I work?</h4>

                        <p>
                           Though I’m now based in Cornwall. I’m pretty flexible
                           with where I work. I frequently travel back to London
                           where I often work at the excellent <a href="http://www.pressplaystudio.co.uk/" title="Press Play studios, Truro" target="_blank" rel="noopener noreferrer">Press Play Studios{ }</a> which offers the best value for money of any
                           studio I’ve worked in. The gear is great and the
                           rates are amazing.
                        </p>

                        <p>
                           In Cornwall, I have worked at the excellent <a href="https://cuberecording.com/" title="Cube Studios, Truro" target="_blank" rel="noopener noreferrer">Cube
                           Studios</a>, which is just down the road from me in St
                           Agnes. I also have my own set up at home.
                        </p>
                     </div>
                     <div className="right">
                        <Parallax
                           y={["-50px", "50px"]}
                           disabled={this.state.narrowScreen}
                        >
                           <Image
                              fluid={pageImages.find(
                                 image =>
                                    image.originalName === "LWT pressplay.jpg"
                              )}
                           />
                        </Parallax>
                     </div>
                  </div>

                  <div className="articleSection row4">
                     <div className="left">
                        <Parallax
                           y={["-50px", "50px"]}
                           disabled={this.state.narrowScreen}
                        >
                           <Image
                              fluid={pageImages.find(
                                 image =>
                                    image.originalName === "cornishcoast.jpg"
                              )}
                           />
                        </Parallax>
                     </div>
                     <div className="right">
                        <p>
                           If you’ve been to Cornwall before you know, it’s a
                           truly inspiring environment and I’m excited to now be
                           offering residential writing / production sessions.
                        </p>

                        <p>
                           Come down and stay with me for a super productive
                           session away from distractions and life.
                        </p>

                        <p>
                           In some places I work there isn’t even phone signal.
                        </p>

                        <p>
                           Imagine that.{" "}
                           <span role="img" aria-label="lil heart-face emoji">
                              😍
                           </span>{" "}
                        </p>
                     </div>
                  </div>
               </Layout>
            </div>
         </ParallaxProvider>
      )
   }
}

export default AboutMePage

export const data = graphql`
   query MyQuery {
      allFile(filter: { relativeDirectory: { eq: "about-page" } }) {
         nodes {
            name
            relativeDirectory
            childImageSharp {
               fluid {
                  aspectRatio
                  base64
                  originalImg
                  originalName
                  presentationHeight
                  presentationWidth
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                  tracedSVG
               }
            }
         }
      }
   }
`
